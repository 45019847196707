import { AxiosError } from 'axios';
import { Button } from 'components/Button';
import { BASE_URL } from 'configs/api';
import { useNotify } from 'hooks/useToast';
import { useForm } from 'react-hook-form';
import { networksApi } from 'services/networks';
import useSWR from 'swr';
import { ICurrency, INetworkWithCurrencies } from 'types/networks';

export interface INetworkLimitsForm {
  networks: INetworkWithCurrencies[];
}

export function NetworkDestinationLimits() {
  const { data, mutate } = useSWR('networks', () => networksApi.getNetworks(), {
    revalidateOnFocus: false,
  });
  const { notify } = useNotify();

  const form = useForm<INetworkLimitsForm>({
    values: {
      networks: data || [],
    },
  });

  const handleSubmit = async () => {
    try {
      const dirtyFields = form.formState.dirtyFields.networks;

      const formValues = form.getValues();

      // Update every modified network
      for (const key in dirtyFields) {
        const dirtyField = dirtyFields[
          key as keyof typeof dirtyFields
        ] as Partial<INetworkWithCurrencies>;

        const network = formValues.networks[
          key as keyof typeof formValues.networks
        ] as INetworkWithCurrencies;

        // if currencies are modified, update them first
        if (dirtyField.currencies && dirtyField.currencies.length > 0) {
          // Update every modified currency for the network
          for (const cKey in dirtyField.currencies) {
            const currency = network.currencies[
              cKey as keyof typeof network.currencies
            ] as ICurrency;

            await networksApi.setCurrencyDestinationLimit(
              currency.id,
              +currency.min_receive,
              +currency.max_receive
            );
          }
        }
      }

      await mutate();
      notify({
        meassage: 'All changes saved!',
        type: 'success',
      });
    } catch (error) {
      if (error instanceof AxiosError) {
        notify({
          meassage: error.response?.data.message || error.message,
          type: 'error',
        });
      } else if (error instanceof Error) {
        notify({
          meassage: error.message,
          type: 'error',
        });
      }
    }
  };

  return (
    <form
      onSubmit={form.handleSubmit(handleSubmit)}
      className="mb-[3.75rem] md:mb-[3.3125rem] md:pb-[3.3125rem] md:border-b border-[#B5B2C9] border-opacity-30"
    >
      <div className="flex justify-between items-center pb-10">
        <h2 className="font-medium text-lg leading-5 md:text-2xl">
          Network destination limits
        </h2>
        <Button
          type="submit"
          disabled={!form.formState.isDirty || form.formState.isSubmitting}
          extraClass="hidden md:block"
        >
          {form.formState.isSubmitting ? 'Saving...' : 'Save changes'}
        </Button>
      </div>
      <div className="flex flex-col gap-y-4 lg:gap-y-[3.75rem]">
        {data &&
          data.map((network, i) => {
            return (
              <div
                className="grid grid-cols-1 lg:grid-cols-3 xl:grid-cols-4 p-5 gap-10 rounded-3xl bg-white bg-opacity-10 lg:p-0 lg:bg-transparent"
                key={network.id}
              >
                <div className="flex justify-center items-start -mb-2 text-sm leading-[1.0625rem] lg:mb-0 lg:justify-start">
                  <div className="flex items-center">
                    <div className="flex justify-center items-center mr-3 w-7 min-w-7 h-7 bg-white rounded-full">
                      <img
                        src={BASE_URL + network.network_image_url}
                        className="w-[1.3rem] min-w-[1.3rem] h-[1.3rem]"
                        alt=""
                      />
                    </div>
                    <div className="mr-auto">{network.name}</div>
                  </div>
                </div>
                <div className="grid lg:col-span-3 lg:grid-cols-3 gap-10">
                  {network.currencies.map((currency, j) => {
                    return (
                      <div className="flex flex-col gap-6" key={currency.id}>
                        <div className="flex items-center text-sm leading-[1.0625rem]">
                          <div className="flex justify-center items-center w-7 min-w-7 h-7 mr-3 rounded-full bg-white">
                            <img
                              src={BASE_URL + currency.image_url}
                              className="w-[1.4rem] min-w-[1.4rem] h-[1.4rem]"
                              alt=""
                            />
                          </div>
                          <div className="mr-auto">{currency.symbol} Limit</div>
                        </div>
                        <div className="flex gap-[0.8125rem]">
                          <div className="flex flex-col gap-2 grow">
                            <span className="font-medium text-sm leading-[1.0625rem] cursor-text">
                              Min
                            </span>
                            <input
                              type="number"
                              min={0.000001}
                              step={0.000001}
                              required
                              className="grow w-full flex lg:max-w-[145px] items-center h-12 px-5 py-[0.9375rem] rounded-lg bg-white bg-opacity-10 font-medium text-sm leading-[1.0625rem] text-white outline-none cursor-text input-number"
                              {...form.register(
                                `networks.${i}.currencies.${j}.min_receive`
                              )}
                            />
                          </div>
                          <div className="flex flex-col gap-2 grow">
                            <span className="font-medium text-sm leading-[1.0625rem] cursor-text">
                              Max
                            </span>
                            <input
                              type="number"
                              min={0.000001}
                              step={0.000001}
                              required
                              className="grow w-full flex lg:max-w-[145px] items-center h-12 px-5 py-[0.9375rem] rounded-lg bg-white bg-opacity-10 font-medium text-sm leading-[1.0625rem] text-white outline-none cursor-text input-number"
                              {...form.register(
                                `networks.${i}.currencies.${j}.max_receive`
                              )}
                            />
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            );
          })}
      </div>
      <Button
        disabled={!form.formState.isDirty || form.formState.isSubmitting}
        extraClass="block mx-auto mt-8 md:hidden"
        type="submit"
      >
        {form.formState.isSubmitting ? 'Saving...' : 'Save changes'}
      </Button>
    </form>
  );
}
