import clsx from 'clsx';
import { Controller, useFormContext } from 'react-hook-form';

import { BASE_URL } from 'configs/api';
import { ICurrency } from 'types/networks';
import { useImgPreloader } from 'hooks/useImagePreload';
import { ISwapFeesForm } from '.';

interface SwapFeeItemProps {
  currency: ICurrency;
  i: number;
}

export function SwapFeeItem({ currency, i }: SwapFeeItemProps) {
  const form = useFormContext<ISwapFeesForm>();

  const { imgsPreloaded } = useImgPreloader([BASE_URL + currency.image_url]);

  return (
    <div className="inline-flex flex-col">
      <div className={clsx('flex items-center text-sm leading-[1.0625rem')}>
        <div className="relative flex justify-center items-center w-7 min-w-7 h-7 mr-3 rounded-full bg-white">
          <img
            src={BASE_URL + currency.image_url}
            className={clsx(
              'w-[1.3rem] min-w-[1.3rem] h-[1.3rem] transition-all duration-200',
              imgsPreloaded ? 'opacity-100' : 'opacity-0'
            )}
            alt=""
          />
        </div>
        <div className={clsx('mr-auto transition-colors duration-200')}>
          {currency.symbol}
        </div>
        <Controller
          name={`currencies.${i}.swap_fee`}
          control={form.control}
          rules={{ required: true }}
          render={({ field }) => (
            <input
              type="number"
              min={0.000001}
              step={0.000001}
              className="grow w-full flex lg:max-w-[145px] items-center h-12 px-5 py-[0.9375rem] rounded-lg bg-white bg-opacity-10 font-medium text-sm leading-[1.0625rem] text-white outline-none cursor-text input-number"
              value={field.value}
              onChange={(e) => {
                field.onChange(e.target.value);
              }}
              required
            />
          )}
        />
      </div>
    </div>
  );
}
