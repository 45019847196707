import clsx from 'clsx';
import { Controller, useFormContext } from 'react-hook-form';

import { IHedgeTokensForm } from '.';
import { Switch } from 'components/Switch';
import { IHedgeToken } from 'types/hedgeTokens';
import { useImgPreloader } from 'hooks/useImagePreload';
import { BASE_URL } from 'configs/api';

interface TokenItemProps {
  token: IHedgeToken;
  i: number;
}

export function TokenItem({ token, i }: TokenItemProps) {
  const form = useFormContext<IHedgeTokensForm>();

  const { imgsPreloaded } = useImgPreloader([BASE_URL + token.img_url]);

  const tokensDisabled = form.watch(`tokens.${i}.hedge`);

  return (
    <div className="inline-flex flex-col">
      <div
        className={clsx(
          'flex items-center text-sm leading-[1.0625rem',
          tokensDisabled && 'disabled'
        )}
      >
        <div className="relative flex justify-center items-center w-7 min-w-7 h-7 mr-3 bg-white rounded-full">
          <div
            className={clsx(
              'absolute inset-0 bg-[#A39DC7] rounded-full',
              tokensDisabled ? 'opacity-0' : 'opacity-100'
            )}
          />
          {
            <img
              src={BASE_URL + token.img_url}
              className={clsx(
                'w-[1.3rem] min-w-[1.3rem] h-[1.3rem] transition-all duration-200',
                !tokensDisabled && 'mix-blend-luminosity',
                imgsPreloaded ? 'opacity-100' : 'opacity-0'
              )}
              alt=""
            />
          }
        </div>
        <div
          className={clsx(
            'mr-auto transition-colors duration-200',
            tokensDisabled ? 'text-white' : 'text-[#A39DC7]'
          )}
        >
          {token.symbol}
        </div>
        <div className="relative flex items-center ml-6 pr-6">
          <Controller
            name={`tokens.${i}.hedge`}
            control={form.control}
            render={({ field }) => (
              <Switch
                checked={field.value}
                onCheckedChange={(val) => {
                  field.onChange(val);
                }}
              />
            )}
          />
        </div>
      </div>
    </div>
  );
}
