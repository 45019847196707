import { DropdownIcon } from 'assets/icons/DropdownIcon';
import GradientCheckIcon from 'assets/icons/gradientCheckIcon.svg';
import EditIcon from 'assets/icons/purpleEditIcon.svg';
import { AxiosError } from 'axios';
import { Button } from 'components/Button';
import { Modal } from 'components/Modal';
import { Switch } from 'components/Switch';
import { BASE_URL } from 'configs/api';
import { useNotify } from 'hooks/useToast';
import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { networkRpcApi } from 'services/networkRpc';
import useSWR, { mutate } from 'swr';
import { NetworkWithRpc } from 'types/networkRpc';
import { isValidUrl } from 'utils/string';

interface NetworkRpcForm {
  name: string;
  url: string;
  api_key?: string;
}

export function NetworkFERpc() {
  const { register, handleSubmit, control, setValue, watch } =
    useForm<NetworkRpcForm>();
  const {
    register: registerCreate,
    handleSubmit: handleSubmitCreate,
    control: controlCreate,
    reset: resetCreate,
  } = useForm<NetworkRpcForm>();

  const { notify } = useNotify();
  const [networks, setNetworks] = useState<NetworkWithRpc[]>([]);
  const [initialNetworks, setInitialNetworks] = useState<NetworkWithRpc[]>([]);
  const [editingRpc, setEditingRpc] = useState<string | null>(null);
  const [openNetworks, setOpenNetworks] = useState<{ [key: string]: boolean }>(
    {}
  );
  const [deprecatedStates, setDeprecatedStates] = useState<{
    [key: string]: boolean;
  }>({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentNetworkId, setCurrentNetworkId] = useState<string | null>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  async function fetchData() {
    const data = await networkRpcApi.getNetworks(true);
    setNetworks(data);
    setInitialNetworks(JSON.parse(JSON.stringify(data)));

    const initialDeprecatedStates: { [key: string]: boolean } = {};
    data.forEach((network) => {
      network.rpcs.forEach((rpc) => {
        initialDeprecatedStates[`${network.network.id}-${rpc.id}`] =
          rpc.is_frontend_used;
      });
    });
    setDeprecatedStates(initialDeprecatedStates);
  }

  useSWR('/networks_with_rpcs/true', () => fetchData(), {
    revalidateOnFocus: false,
  });

  const onSubmit = async () => {
    setIsSubmitting(true);

    try {
      const modifiedNetworks = networks.map((network) => {
        const modifiedRpcs = network.rpcs.map((rpc) => {
          const { id, ...restRpc } = rpc;
          return rpc.id.startsWith('new-')
            ? { ...restRpc, network_id: network.network.id }
            : rpc;
        });
        return { ...network, rpcs: modifiedRpcs };
      });

      const rpcs = modifiedNetworks.map((network) => network.rpcs).flat();

      await networkRpcApi.updateNetworks(rpcs);

      await mutate('/networks_with_rpcs/true');
      await mutate('/networks_with_rpcs/false');

      notify({
        meassage: 'All changes saved!',
        type: 'success',
      });
    } catch (e) {
      if (e instanceof AxiosError) {
        notify({
          meassage: e.response?.data.message || e.message,
          type: 'error',
        });
      } else if (e instanceof Error) {
        notify({
          meassage: e.message,
          type: 'error',
        });
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  const toggleNetwork = (networkId: string) => {
    setOpenNetworks((prev) => ({
      ...prev,
      [networkId]: !prev[networkId],
    }));
  };

  const handleRpcChange = (
    networkId: string,
    rpcId: string,
    changes: Partial<{
      name: string;
      url: string;
      deprecated: boolean;
      is_frontend_used: boolean;
      api_key: string;
    }>
  ) => {
    setNetworks((prevNetworks) =>
      prevNetworks.map((network) =>
        network.network.id === networkId
          ? {
              ...network,
              rpcs: network.rpcs.map((rpc) =>
                rpc.id === rpcId ? { ...rpc, ...changes } : rpc
              ),
            }
          : network
      )
    );
  };

  const handleSwitchChange = (
    networkId: string,
    rpcId: string,
    checked: boolean
  ) => {
    setDeprecatedStates((prev) => ({
      ...prev,
      [`${networkId}-${rpcId}`]: checked,
    }));
    handleRpcChange(networkId, rpcId, {
      is_frontend_used: checked,
    });
  };

  const handleAddNewRpc = (data: NetworkRpcForm) => {
    if (!isValidUrl(data.url)) {
      notify({
        meassage: `Invalid RPC url`,
        type: 'error',
      });
      return;
    }

    const newRpc = {
      id: `new-${Date.now().toString()}`,
      deprecated: true,
      name: data.name,
      url: data.url,
      api_key: data.api_key,
      used: false,
      is_frontend_used: true,
    };

    setNetworks((prevNetworks) =>
      prevNetworks.map((network) =>
        network.network.id === currentNetworkId
          ? {
              ...network,
              rpcs: [...network.rpcs, newRpc],
            }
          : network
      )
    );

    setIsModalOpen(false);
    resetCreate();
  };

  const columns: NetworkWithRpc[][] = [[], [], []];
  networks.forEach((network, index) => {
    columns[index % 3].push(network);
  });

  const isSaveDisabled =
    JSON.stringify(networks) === JSON.stringify(initialNetworks);

  return (
    <>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="pb-[3.3125rem] mb-[3.3125rem] md:border-b border-[#B5B2C9] border-opacity-30"
      >
        <div className="flex justify-between items-center pb-8 md:pb-10">
          <h2 className="font-medium text-lg leading-5 md:text-2xl">
            Network RPC (Frontend)
          </h2>
          <Button
            type="submit"
            extraClass="hidden md:block"
            disabled={isSaveDisabled || isSubmitting}
          >
            {isSubmitting ? 'Saving...' : 'Save changes'}
          </Button>
        </div>

        <div className="flex flex-wrap justify-between">
          {columns.map((column, colIndex) => (
            <div
              key={colIndex}
              className="flex flex-col gap-[16px] w-full md:w-[49%] lg:w-[32%] lg:mb-0 mb-[16px]"
            >
              {column.map((network) => (
                <div
                  key={network.network.id}
                  className="h-fit rounded-[20px] bg-[#FFFFFF1A] flex flex-col p-[24px] transition-all duration-300"
                >
                  <div className="w-full flex justify-between items-center">
                    <div className="flex gap-[12px]">
                      <div className="flex justify-center items-center w-7 min-w-7 h-7 bg-white rounded-full">
                        <img
                          src={BASE_URL + network.network.network_image_url}
                          alt=""
                          className="w-[1.3rem] min-w-[1.3rem] h-[1.3rem]"
                        />
                      </div>
                      <p>{network.network.name}</p>
                    </div>
                    <DropdownIcon
                      onClick={() => toggleNetwork(network.network.id)}
                      className={`cursor-pointer ${
                        openNetworks[network.network.id] ? 'rotate-180' : ''
                      }`}
                    />
                  </div>

                  {openNetworks[network.network.id] && (
                    <>
                      <div className="flex flex-col gap-[16px] my-[24px] items-start">
                        {network.rpcs
                          .filter((rpc) => !!rpc.name)
                          .map((rpc) => (
                            <div key={rpc.id} className="w-full">
                              <div className="flex gap-[8px] items-center w-full justify-between">
                                <div className="flex gap-[8px] items-center">
                                  <Switch
                                    checked={
                                      deprecatedStates[
                                        `${network.network.id}-${rpc.id}`
                                      ]
                                    }
                                    onCheckedChange={(checked) =>
                                      handleSwitchChange(
                                        network.network.id,
                                        rpc.id,
                                        checked
                                      )
                                    }
                                  />
                                  <p>{rpc.name}</p>
                                </div>
                                <div
                                  className="flex gap-[8px] font-[700] text-[14px] items-center cursor-pointer"
                                  onClick={() => {
                                    if (editingRpc === rpc.id) {
                                      const name = watch('name');
                                      const url = watch('url');
                                      const api_key = watch('api_key');

                                      if (!name || !url) {
                                        notify({
                                          meassage: 'Name and url are required',
                                          type: 'error',
                                        });
                                        return;
                                      }

                                      if (!isValidUrl(url)) {
                                        notify({
                                          meassage: `Invalid RPC url for ${network.network.name}`,
                                          type: 'error',
                                        });
                                        return;
                                      }

                                      setEditingRpc(null);
                                      handleRpcChange(
                                        network.network.id,
                                        rpc.id,
                                        {
                                          name,
                                          url,
                                          api_key,
                                        }
                                      );
                                    } else {
                                      setEditingRpc(rpc.id);
                                      setValue('name', rpc.name);
                                      setValue('url', rpc.url);
                                      setValue('api_key', rpc.api_key);
                                    }
                                  }}
                                >
                                  {editingRpc === rpc.id ? (
                                    <>
                                      <img
                                        src={GradientCheckIcon}
                                        alt=""
                                        className="w-[16px] h-[16px]"
                                      />
                                      <span
                                        style={{
                                          background:
                                            'linear-gradient(90deg, #E5C4FF 0%, #9D8DFF 100%)',
                                          WebkitBackgroundClip: 'text',
                                          WebkitTextFillColor: 'transparent',
                                        }}
                                      >
                                        Save
                                      </span>
                                    </>
                                  ) : (
                                    <img src={EditIcon} alt="" />
                                  )}
                                </div>
                              </div>

                              {editingRpc === rpc.id && (
                                <>
                                  <div className="flex flex-col gap-[8px] mt-4">
                                    <p>Name</p>
                                    <input
                                      className="rounded-[8px] h-[48px] w-full px-[20px] bg-[#FFFFFF1A] flex items-center"
                                      style={{ outline: 'none' }}
                                      required
                                      {...register('name', { required: true })}
                                    />
                                  </div>

                                  <div className="flex flex-col gap-[8px] mt-4">
                                    <p>Rpc link</p>
                                    <Controller
                                      name="url"
                                      control={control}
                                      rules={{ required: true }}
                                      render={({ field }) => (
                                        <textarea
                                          className="rounded-[8px] h-[104px] py-[12px] w-full px-[20px] bg-[#FFFFFF1A] flex items-center"
                                          style={{
                                            outline: 'none',
                                            resize: 'none',
                                          }}
                                          required
                                          {...field}
                                        />
                                      )}
                                    />
                                  </div>

                                  <div className="flex flex-col gap-[8px] mt-4">
                                    <p>Api Key</p>
                                    <Controller
                                      name="api_key"
                                      control={control}
                                      rules={{ required: false }}
                                      render={({ field }) => (
                                        <textarea
                                          className="rounded-[8px] h-[104px] py-[12px] w-full px-[20px] bg-[#FFFFFF1A] flex items-center"
                                          style={{
                                            outline: 'none',
                                            resize: 'none',
                                          }}
                                          {...field}
                                        />
                                      )}
                                    />
                                  </div>
                                </>
                              )}
                            </div>
                          ))}
                      </div>

                      <div
                        className="flex items-center gap-[8px] font-[700] text-[14px] cursor-pointer w-fit"
                        style={{
                          background:
                            'linear-gradient(90deg, #E5C4FF 0%, #9D8DFF 100%)',
                          WebkitBackgroundClip: 'text',
                          WebkitTextFillColor: 'transparent',
                        }}
                        onClick={() => {
                          setCurrentNetworkId(network.network.id);
                          setIsModalOpen(true);
                        }}
                      >
                        <span className="text-[18px]">+</span> Add new RPC
                      </div>
                    </>
                  )}
                </div>
              ))}
            </div>
          ))}
        </div>

        <Button
          type="submit"
          extraClass="block md:hidden mx-auto mt-6"
          disabled={isSaveDisabled || isSubmitting}
        >
          {isSubmitting ? 'Saving...' : 'Save changes'}
        </Button>
      </form>

      <Modal
        isOpen={isModalOpen}
        onOpenChange={(val) => {
          setIsModalOpen(val);
          if (!val) {
            resetCreate();
          }
        }}
      >
        <div className="p-[24px] bg-[#FFFFFF1A] w-[90vw] sm:w-[428px] rounded-[20px] shadow-lg">
          <div className="flex gap-[12px] mb-4">
            {currentNetworkId && (
              <>
                <img
                  src={
                    BASE_URL +
                    networks.find((net) => net.network.id === currentNetworkId)
                      ?.network.network_image_url
                  }
                  alt=""
                  className="w-[28px] h-[28px]"
                />
                <p>
                  {
                    networks.find((net) => net.network.id === currentNetworkId)
                      ?.network.name
                  }
                </p>
              </>
            )}
          </div>
          <form
            onSubmit={handleSubmitCreate(handleAddNewRpc)}
            className="flex flex-col gap-4"
          >
            <div className="flex flex-col gap-2">
              <label htmlFor="nameCreate">Name</label>
              <input
                id="nameCreate"
                className="rounded-[8px] h-[48px] w-full px-[20px] bg-[#FFFFFF1A]"
                style={{ outline: 'none' }}
                required
                {...registerCreate('name', { required: true })}
              />
            </div>
            <div className="flex flex-col gap-2">
              <label htmlFor="urlCreate">RPC link</label>
              <Controller
                name="url"
                control={controlCreate}
                rules={{ required: true }}
                render={({ field }) => (
                  <textarea
                    id="urlCreate"
                    className="rounded-[8px] h-[104px] py-[12px] w-full px-[20px] bg-[#FFFFFF1A]"
                    style={{
                      outline: 'none',
                      resize: 'none',
                    }}
                    required
                    {...field}
                  />
                )}
              />
            </div>

            <div className="flex flex-col gap-[8px] mt-4">
              <label htmlFor="apiKeyCreate">Api Key</label>
              <Controller
                name="api_key"
                control={controlCreate}
                rules={{ required: false }}
                render={({ field }) => (
                  <textarea
                    id="apiKeyCreate"
                    className="rounded-[8px] h-[104px] py-[12px] w-full px-[20px] bg-[#FFFFFF1A]"
                    style={{
                      outline: 'none',
                      resize: 'none',
                    }}
                    {...field}
                  />
                )}
              />
            </div>
            <button
              type="submit"
              className="flex gap-[8px] items-center font-[700] text-[14px] w-fit"
            >
              <img
                src={GradientCheckIcon}
                alt=""
                className="w-[16px] h-[16px]"
              />
              <span
                style={{
                  background:
                    'linear-gradient(90deg, #E5C4FF 0%, #9D8DFF 100%)',
                  WebkitBackgroundClip: 'text',
                  WebkitTextFillColor: 'transparent',
                }}
              >
                Save
              </span>
            </button>
          </form>
        </div>
      </Modal>
    </>
  );
}
