import clsx from 'clsx';
import { useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { BASE_URL } from 'configs/api';
import { DropdownIcon } from 'assets/icons/DropdownIcon';
import { Switch } from 'components/Switch';
import { ISwapPairsStatusForm } from '.';
import { useImgPreloader } from 'hooks/useImagePreload';
import { ISwapPair } from 'types/pairs';

interface SwapPairItemProps {
  pair: ISwapPair;
  i: number;
}

export function SwapPairItem({ pair, i }: SwapPairItemProps) {
  const [isExpanded, setIsExpanded] = useState(true);
  const form = useFormContext<ISwapPairsStatusForm>();

  const { imgsPreloaded } = useImgPreloader([
    BASE_URL + pair.icon,
    ...pair.pairs.map((p) => BASE_URL + p.icon),
  ]);

  const pairsDisabled = form.watch(`pairs.${i}.disabled`);

  return (
    <div className="inline-flex flex-col">
      <div
        className={clsx(
          'flex items-center text-sm leading-[1.0625rem',
          !pairsDisabled && 'disabled'
        )}
      >
        <div className="relative flex justify-center items-center w-7 min-w-7 h-7 mr-3 bg-white rounded-full">
          <div
            className={clsx(
              'absolute inset-0 bg-[#A39DC7] rounded-full',
              !pairsDisabled ? 'opacity-0' : 'opacity-100'
            )}
          />
          <img
            src={BASE_URL + pair.icon}
            className={clsx(
              'w-[1.3rem] min-w-[1.3rem] h-[1.3rem] transition-all duration-200',
              pairsDisabled && 'mix-blend-luminosity',
              imgsPreloaded ? 'opacity-100' : 'opacity-0'
            )}
            alt=""
          />
        </div>
        <div
          className={clsx(
            'mr-auto transition-colors duration-200',
            !pairsDisabled ? 'text-white' : 'text-[#A39DC7]'
          )}
        >
          {pair.symbol}
        </div>
        <div className="relative flex items-center ml-6 pr-6">
          <Controller
            name={`pairs.${i}.disabled`}
            control={form.control}
            render={({ field }) => (
              <Switch
                checked={!field.value}
                onCheckedChange={(val) => {
                  const updatedDisabled = !val;
                  field.onChange(updatedDisabled);

                  // Get the current pairs array
                  const currentPairs = form.getValues(`pairs.${i}.pairs`);

                  // Update the disabled status for all nested pairs
                  const updatedPairs = currentPairs.map((pair) => ({
                    ...pair,
                    disabled: updatedDisabled,
                  }));

                  // Set the updated pairs array back to the form state
                  form.setValue(`pairs.${i}.pairs`, updatedPairs, {
                    shouldDirty: true,
                  });
                }}
              />
            )}
          />
          <button
            type="button"
            className="absolute right-0 bg-transparent border-none"
            onClick={() => setIsExpanded(!isExpanded)}
          >
            <DropdownIcon className={clsx(isExpanded && 'rotate-180')} />
          </button>
        </div>
      </div>
      {isExpanded && (
        <div className="flex flex-col gap-4 mt-[0.8125rem] ml-[2.5rem]">
          {pair.pairs.map((p, j) => {
            const disabled = form.watch(`pairs.${i}.pairs.${j}.disabled`);

            return (
              <div
                className="flex items-center gap-3 text-sm leading-[1.0625rem]"
                key={p.pair}
              >
                <div
                  className={clsx(
                    'flex justify-center items-center w-5 min-w-5 h-5 rounded-full bg-white transition-opacity duration-200',
                    imgsPreloaded ? 'opacity-100' : 'opacity-0'
                  )}
                >
                  <img
                    src={BASE_URL + p.icon}
                    className="w-4 min-w-4 h-4"
                    alt=""
                  />
                </div>
                <div
                  className={clsx(
                    'mr-auto transition-colors duration-200',
                    disabled || pairsDisabled ? 'text-[#A39DC7]' : 'text-white'
                  )}
                >
                  {p.pair}
                </div>
                <div className="ml-auto mr-6">
                  <Controller
                    name={`pairs.${i}.pairs.${j}.disabled`}
                    control={form.control}
                    render={({ field }) => (
                      <Switch
                        checked={!field.value}
                        onCheckedChange={(val) => {
                          field.onChange(!val);
                        }}
                      />
                    )}
                  />
                </div>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
}
